import React, { useState, useEffect, useContext } from "react"

export default function FAQList({ intl }) {

  const faqs = [
    {
      h: intl.formatMessage({ id: "f1h" }),
      t: intl.formatMessage({ id: "f1t" })
    },
    {
      h: intl.formatMessage({ id: "f2h" }),
      t: intl.formatMessage({ id: "f2t" })
    },
    {
      h: intl.formatMessage({ id: "f3h" }),
      t: intl.formatMessage({ id: "f3t" })
    },
    {
      h: intl.formatMessage({ id: "f4h" }),
      t: intl.formatMessage({ id: "f4t" })
    },
    {
      h: intl.formatMessage({ id: "f5h" }),
      t: intl.formatMessage({ id: "f5t" })
    },
    {
      h: intl.formatMessage({ id: "f6h" }),
      t: intl.formatMessage({ id: "f6t" })
    },
    {
      h: intl.formatMessage({ id: "f7h" }),
      t: intl.formatMessage({ id: "f7t" })
    },
    {
      h: intl.formatMessage({ id: "f8h" }),
      t: intl.formatMessage({ id: "f8t" })
    },
  ]

  return (
    <div className="faq-list">
      <h3>{intl.formatMessage({ id: "faq-headline", default: "FAQ" })}</h3>
      <div className="faq-list-scroll">
        {
          faqs.map((f, i) => {
            return (
              <div className="faq-item" key={i}>
                <h4 dangerouslySetInnerHTML={{ __html: f.h }}></h4>
                <p dangerouslySetInnerHTML={{ __html: f.t }}></p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
