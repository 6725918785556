import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function ScrollDown({ visible, swiperObjMain }) {

  const intl = useIntl()
  const { width } = useWindowDimensions();

  const showNext = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      if (width >= 1200) {
        if (swiperObjMain) {
          event.preventDefault()
          swiperObjMain.slideNext()
        }
      }
    }
  }


  return (
    <a
      href={`#next`}
      className={`scroll-down ${visible ? 'visible' : ''}`}
      aria-label={intl.formatMessage({ id: "scroll-down", default: "Scroll down" })}
      onClick={(event) => showNext(event)}
      onKeyDown={(event) => showNext(event)}
      tabIndex={0}
    >
      <span className="mouse-scroll">
        <span className="mouse">
          <span className="wheel"></span>
        </span>
        <span>
          <span className="m-arrow ma1"></span>
          <span className="m-arrow ma2"></span>
        </span>
      </span>
    </a>
  )
}