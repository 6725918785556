import React from 'react';
import { useIntl } from "gatsby-plugin-intl"

export default function Expandable({ title, text, isExpanded, index, open }) {

  const intl = useIntl()

  const expand = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      open(index);
    }
  }

  return (
    <article className={`expandable ${isExpanded ? 'expanded' : ''}`} >
      <h4
        className="expander"
      >
        <button
          onClick={(event) => expand(event)}
          onKeyDown={(event) => expand(event)}
          aria-label={intl.formatMessage({ id: "show" })}
          dangerouslySetInnerHTML={{ __html: title }}
        ></button>
      </h4>
      <div className="text">{text}</div>
    </article>
  );
};
