import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const isClient = typeof window === "object" ? true : false
  var width = 1000, height = 1000

  if (isClient) {
    width = window.innerWidth
    height = window.innerHeight
  }

  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}