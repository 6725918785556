import React, { useState } from 'react';
import { Expandable } from '../components'

export default function Expandables({ contents }) {

  const [openedIndex, setOpenedIndex] = useState(false);

  const open = (index) => {
    if (openedIndex === index) {
      index = false;
    }
    setOpenedIndex(index)
  }


  return (
    <div className="faq expandables">
      {
        contents.map((content, i) => {
          return <Expandable key={i} title={content.title} text={content.text} isExpanded={openedIndex === i} index={i} open={open} />
        })
      }
    </div>
  );
};
