import React from "react";

export default function LevelsWithInfo({ intl }) {

  return (
    <div className="levels-wrap">
      <figure className="levels-info"></figure>
      <ul>
        <li>&nbsp;</li>
        <li>&nbsp;</li>
        <li>{intl.formatMessage({ id: "expl3" })}</li>
        <li>&nbsp;</li>
        <li>&nbsp;</li>
        <li>&nbsp;</li>
      </ul>
    </div>
  )

}