import React from "react";
import { useIntl } from "gatsby-plugin-intl"

export default function PercentageBubles() {
  const intl = useIntl()
  return (
    <figure className="bubles">

      <div>
        <p>
          <strong>{intl.formatMessage({ id: "bouble-1-h" })}</strong>
        </p>
      </div>
      <div>
        <p>
          <strong>{intl.formatMessage({ id: "bouble-2-h" })}</strong>
        </p>
      </div>
      <div>
        <p>
          <strong>{intl.formatMessage({ id: "bouble-3-h" })}</strong>
        </p>
      </div>

    </figure>
  )
}