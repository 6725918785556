import React from "react";
import { Logo, LanguageSwitch, FAQTrigger } from "../../components";
import Overlay from "./Overlay";

const Header = () => {
  return (
    <header id="header">
      <div className="wrap">
        <Logo />
        <div className="triggers">
          <FAQTrigger />
          <LanguageSwitch />
        </div>
        <Overlay />
      </div>
    </header>
  );
};
export default Header;
