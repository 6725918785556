import React from "react";
import { MailLink } from "../components";
import useSiteMetadata from "../hooks/useSiteMetadata";
import { useIntl } from "gatsby-plugin-intl"

const Footer = () => {
  const intl = useIntl()
  const { title, email } = useSiteMetadata();

  return (
    <footer>
      <div className="wrap bottom">
        <p className="rights">
          &copy; {new Date().getFullYear()} {title}
        </p>
        <p className="contact-us">
          <MailLink email={email} text={intl.formatMessage({ id: "contact-us" })} />
        </p>
      </div>
    </footer>
  );
};
export default Footer;
