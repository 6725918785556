import React, { useEffect } from "react";
import { VideoContainer, ScrollDown } from "../components";
import ThemeContext from "../stores/ThemeContext"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, EffectFade } from 'swiper'; //Pagination
import useWindowDimensions from "../hooks/useWindowDimensions";

SwiperCore.use([Controller, EffectFade]);

export default function Section({ num, title, video, videoText, textDelay, videoButton, children, illustration, illustrationMobile, onSwiperReady, swiper, imageOverlay }) {

  // const { width } = useWindowDimensions();
  let { dispatch, themeOpt } = React.useContext(ThemeContext)
  //const [swiper, setSwiper] = useState(null);

  const setSwiperWithCallback = swiper => {
    if (swiper) {
      //setSwiper(swiper)
      if (onSwiperReady) {
        onSwiperReady(swiper)
      }
    }
  };

  return (
    <section className="page " id={`section${num}`}>
      <div className="wrap">

        <div className="current-page">
          <h2>{title}</h2>
        </div>

        <div className={`screen ${illustration === false ? 'no-illustration' : ''}`}>
          <div className="animation" >
            <VideoContainer file={video} text={videoText} textDelay={textDelay} button={videoButton} sectionNum={num} activeSection={themeOpt.activeSection} splashShown={themeOpt.splashShown} inSwiper={swiper?.initialized === true} imageOverlay={imageOverlay} />
          </div>

          <div className="info">
            {children}
          </div>

          {illustration &&
            <div className="illustration">
              {illustration}
              {illustrationMobile && illustrationMobile}
            </div>
          }
        </div>

      </div>
    </section>
  )
}