import React from "react";
import { useIntl } from "gatsby-plugin-intl"

export default function Logo() {
  const intl = useIntl()
  return (
    <div className="logo">
      <span>{intl.formatMessage({ id: "logo-line-1" })}</span>
      <span>{intl.formatMessage({ id: "logo-line-2" })}</span>
      <span>{intl.formatMessage({ id: "logo-line-3" })}</span>
    </div>
  )
}