import React, { useEffect, useState, useContext } from "react";
import ThemeContext from "../../stores/ThemeContext";
import { useIntl } from "gatsby-plugin-intl"

const SplashScreen = () => {

  const intl = useIntl()
  let { dispatch, themeOpt } = useContext(ThemeContext);
  const [animate, setAnimate] = useState(false)

  useEffect(() => {

    setAnimate(true)

    let timer = setTimeout(() => {
      dispatch({ type: "SPLASH_SHOWN", payload: true });
    }, 3500);

    return () => {
      clearTimeout(timer)
    }
  }, [dispatch])

  return (
    <div className={`splash ${themeOpt.splashShown === true ? 'shown' : ''}  ${animate === true ? 'animate' : ''}`}>
      <strong dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "splash-title" }) }}></strong>
    </div >
  );
};
export default SplashScreen;