import React, { useContext } from "react";
import Header from "./Header";
import SplashScreen from "./SplashScreen";
import ThemeContext from "../../stores/ThemeContext";

const Contents = ({ children, containerClass = '' }) => {

  let { themeOpt } = useContext(ThemeContext);

  return (
    <div className={`container ${containerClass} ${themeOpt.splashShown === true ? 'splash-shown' : ''}`}>
      <Header />
      <div className="contents">
        {children}
      </div>
      <SplashScreen />
    </div>
  );
};
export default Contents;