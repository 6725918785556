import React from "react";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl"

export default function Resellers() {

  const intl = useIntl()

  const reselelers = [
    {
      i: 'logo_O2.svg',
      c: 'HU',
      n: 'O2 Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_orange.svg',
      c: 'HU',
      n: 'Orange Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_mall.svg',
      c: 'HU',
      n: 'Mall Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_vodafone.svg',
      c: 'HU',
      n: 'Vodafone Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_O2.svg',
      c: 'HU',
      n: 'O2 Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_orange.svg',
      c: 'HU',
      n: 'Orange Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_mall.svg',
      c: 'HU',
      n: 'Mall Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_vodafone.svg',
      c: 'HU',
      n: 'Vodafone Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_O2.svg',
      c: 'HU',
      n: 'O2 Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_orange.svg',
      c: 'HU',
      n: 'Orange Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_mall.svg',
      c: 'HU',
      n: 'Mall Hungary',
      w: 'http://www.o2.com'
    },
    {
      i: 'logo_vodafone.svg',
      c: 'HU',
      n: 'Vodafone Hungary',
      w: 'http://www.o2.com'
    },
  ]

  return (
    <div className="resellers">
      <h3>{intl.formatMessage({ id: "get-your-phone", default: "Get your phone at our reseller" })}</h3>
      <div className="resellers-table">



        {
          reselelers.map((rs, i) => {
            return (
              <div className="rr" key={i}>
                <IntlContextConsumer>
                  {({ language: currentLocale }) => { return currentLocale === 'en' ? (<span className="country">{rs.c}</span>) : '' }}
                </IntlContextConsumer >
                <figure>
                  <img src={`/img/${rs.i}`} alt={rs.n} />
                </figure>
                <div className="inf">
                  <strong>{rs.n}</strong>
                  <a href={rs.w} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "go-to-website", default: "go to the website" })}</a>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
