import React, { useState, useEffect, useRef } from "react";
import handleViewport from "react-in-viewport";

function VideoContainer({ file, text, textDelay = 1000, button, inViewport, forwardedRef, splashShown, inSwiper, activeSection, sectionNum, imageOverlay }) {
  const [displayText, setdisplayText] = useState(false)
  const [displayOverlayImage, setDisplayOverlayImage] = useState(false)
  const [overlayImage, setOverlayImage] = useState('package_win.jpg')
  const [loaded, setLoaded] = useState(false)
  const isClient = typeof window === "object"
  const videoEl = useRef(null);

  const videoWidth = () => {
    if (isClient && forwardedRef?.current) {
      const vw = Math.round(forwardedRef.current.offsetWidth)
      document.documentElement.style.setProperty("--video-width", `${vw}px`)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      videoWidth()
    }
    window.addEventListener("resize", handleResize)
    videoWidth()

    const isMac = navigator?.platform.toUpperCase().indexOf('MAC') >= 0;
    if (isMac) {
      setOverlayImage('package_mac.jpg')
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isClient])

  useEffect(() => {
    if (videoEl && loaded) {
      //alert('loaded')
    }
  }, [videoEl, loaded])

  /*
    useEffect(() => {
      videoWidth()
      if (videoEl && loaded) {
        videoEl.current.pause();
        videoEl.current.currentTime = 0;
      }
    }, [videoEl, loaded])
    */

  useEffect(() => {
    const isClient = typeof window === "object"

    if (inSwiper) { // desktop
      if (activeSection === sectionNum && loaded && splashShown) { // mobile
        const delayTimeout = window.setTimeout(() => {
          setdisplayText(true)
          if (imageOverlay === true) {
            window.setTimeout(() => {
              setDisplayOverlayImage(true)
            }, 3000)
          }

        }, textDelay)
        return () => {
          window.clearTimeout(delayTimeout)
        }
      }
    } else {
      if (isClient && loaded && inViewport && splashShown) { // mobile
        const delayTimeout = window.setTimeout(() => {
          setdisplayText(true)
          if (imageOverlay === true) {
            window.setTimeout(() => {
              setDisplayOverlayImage(true)
            }, 3000)
          }
        }, textDelay)
        return () => {
          window.clearTimeout(delayTimeout)
        }
      }
    }


  }, [textDelay, loaded, inViewport, splashShown, activeSection, inSwiper, sectionNum])

  useEffect(() => {
    if (!inViewport || (activeSection !== sectionNum && inSwiper) && loaded) { // rewind and pause if not visible
      setdisplayText(false)
      setDisplayOverlayImage(false)
      if (videoEl) {
        videoEl.current.pause();
        videoEl.current.currentTime = 0;
      }
    }

    if (inSwiper) { // desktop
      if (activeSection === sectionNum && videoEl.current && splashShown && loaded) {
        videoEl.current.play();
      }
    } else { // mobile
      if (inViewport && videoEl.current && splashShown && loaded) {
        videoEl.current.play();
      }
    }


  }, [inViewport, splashShown, loaded, activeSection, inSwiper, sectionNum])

  return (
    <figure className="video-wrap">
      <div className="video-container" ref={forwardedRef}>
        <video controls={false} playsInline muted onLoadedMetadata={() => setLoaded(true)} onCanPlay={() => setLoaded(true)} ref={videoEl}>
          <source src={`/video/${file}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {displayText && (
          <div className="transformation">
            <article className="fadeIn">
              <div className="inner-text">
                {text.length > 0 && <h4>{text}</h4>}
                {button && <div className="cta">{button}</div>}
              </div>
            </article>
          </div>)
        }

        {(imageOverlay === true) && <img className={`video-img-overlay ${displayOverlayImage ? 'show' : ''}`} src={`/img/${overlayImage}`} alt="Packaging" />}

      </div>
    </figure>
  )
}

const ViewportBlock = handleViewport(VideoContainer); // , { rootMargin: '0px 0px 0px -250px' }
export default ViewportBlock;