import React, { useState } from "react";
import { Link } from "gatsby";
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"

//import ThemeContext from "../stores/ThemeContext"; 

export default function LanguageSwitch({ t }) {

  const intl = useIntl()
  //let { dispatch } = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const languageNames = {
    'en': 'English',
    'de': 'Deutsch',
    'cs': 'Čeština',
    'sk': 'Slovenčina',
    'pl': 'Polski',
    'hu': 'Magyar',
    'ru': 'Pусский',
    'et': 'Eesti',
    'lt': 'Lietuvių',
    'tv': 'Latvian',
    'hr': 'Hrvatski',
    'sl': 'Slovenščina',
    'sr': 'Srpski',
    'mk': 'Македонски',
    'ro': 'Romanian',
    'bg': 'Български',
    'sq': 'Shqip',
  }

  const toggleMenu = event => {
    if (event.type === "click" || event.key === "Enter" || event.key === " ") {
      setMenuOpen(!menuOpen);
    }
  };

  //let locale = 'cs'

  return (
    <div className={`language-switch ${menuOpen ? 'open' : 'closed'}`}>



      <button
        onClick={event => toggleMenu(event)}
        onKeyDown={event => toggleMenu(event)}
        aria-label={intl.formatMessage({ id: "select-language" })}
        className="current"
      >
        <IntlContextConsumer>
          {({ language: currentLocale }) =>
            <span>{languageNames[currentLocale]}</span>
          }
        </IntlContextConsumer >
      </button>
      {menuOpen && <div className="backdrop" onClick={event => toggleMenu(event)} onKeyDown={event => toggleMenu(event)}></div>}
      <div className="lang-options">
        <ul>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map(language => {

                let name = languageNames[language]

                return (
                  <li key={language} className={language === currentLocale ? 'current-lang' : ''}>
                    <Link onClick={() => changeLocale(language)} to="/">{name}</Link>
                  </li>
                )
              })
            }
          </IntlContextConsumer >
        </ul>
        <button
          onClick={event => toggleMenu(event)}
          onKeyDown={event => toggleMenu(event)}
          aria-label={intl.formatMessage({ id: "close-menu" })}
          className="close"
        >
          x
        </button>
      </div>
    </div >
  )
}