import React, { useContext } from "react"
import { useIntl } from "gatsby-plugin-intl"
import ThemeContext from "../stores/ThemeContext"
import { FAQList } from "../components";

export default function FAQTrigger() {

  const intl = useIntl()
  let { dispatch, themeOpt } = useContext(ThemeContext)

  const showFAQs = event => {
    if (event.type === "click" || event.key === "Enter" || event.key === " ") {
      dispatch({
        type: "SHOW_OVERLAY",
        payload: { overlayContent: <FAQList intl={intl} /> },
      })
    }
  }


  const reselelers = [

  ]

  return (
    <div className="faq-trigger">
      <button
        onClick={event => showFAQs(event)}
        onKeyDown={event => showFAQs(event)}
        aria-label={intl.formatMessage({ id: "faq-trigger-inf" })}
      >
        {intl.formatMessage({ id: "faq-trigger" })}
      </button>
    </div>
  );
}
