import React, { useContext } from "react";
import ThemeContext from "../../stores/ThemeContext";

export default function Overlay({ onClose }) {

  let { dispatch, themeOpt } = useContext(ThemeContext);

  const overlayClick = (event) => {
    if (event.type === "click" || event.key === "Esc") {

      if (onClose) {
        onClose()
      }

      dispatch({ type: "HIDE_OVERLAY", payload: {} });
    }
  };

  const overlayBodyClick = (event) => {
    if (event.type === "click") {
      event.stopPropagation()
      event.preventDefault()
    }
  };

  if (!themeOpt.overlayContent) {
    return ''
  }

  return (
    <div
      className="overlay"
      onClick={event => overlayClick(event)}
    >
      <div
        className="overlay-body"
        onClick={event => overlayBodyClick(event)}
      >
        {themeOpt.overlayContent}
        <button
          onClick={event => overlayClick(event)}
          onKeyDown={event => overlayClick(event)}
          aria-label="Close"
          className="close"
        >
          x
        </button>
      </div>
    </div>
  );
}
